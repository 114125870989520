@use 'libs/theme' as *;

.hero {
	--gallery-aspect-ratio: 1.174;

	@include themed-colors;
	@include themed-spacing(96, 48);

	@include multiplier {
		padding-bottom: var(--responsive-spacing-72);
	}

	margin-top: 0;
	overflow: hidden;

	h1 {
		margin-bottom: var(--responsive-spacing-36);
	}
}

.top {
	display: flex;
	flex-flow: column wrap;
	gap: var(--grid-columns-1);
	margin-bottom: var(--responsive-spacing-72);

	@media (min-width: $breakpoint-md) {
		flex-direction: row-reverse;
		align-items: center;
	}
}

.body {
	width: 100%;

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-5);
	}
}

.images {
	position: relative;
	display: flex;
	flex: auto;
	gap: var(--size-2);
	align-items: center;
	width: auto;
	height: 41vw;
	margin: calc(-1 * var(--size-1)) calc(-1 * var(--size-1)) var(--responsive-spacing-48);

	@media (min-width: $breakpoint-md) {
		width: var(--grid-columns-6);
		height: auto;
		margin-bottom: 0;
		aspect-ratio: var(--gallery-aspect-ratio);
	}
}

.image {
	position: absolute;
	left: auto;
	height: auto;
	padding: var(--size-1);

	&__inner {
		position: relative;
		padding-top: 100%;

		> * {
			position: absolute !important;
			inset: 0;
		}
	}

	&.size-1 {
		width: 25vw;
	}

	&.size-2 {
		width: 45vw;
	}

	&.size-3 {
		width: 23vw;
	}

	&.size-4 {
		width: 32vw;
	}

	@media (min-width: $breakpoint-md) {
		transition: all 1s ease-in-out, top 750ms, left 750ms, width 750ms, height 750ms;
		transition-delay: top 1s, left 1s, width 1s, height 1s;

		--small-image-size: calc(var(--grid-columns-4) - var(--size-2));
		--large-image-size: calc(var(--grid-columns-6) - var(--size-2));

		@mixin calculate-top($top, $offset: 0) {
			top: calc($top * calc(var(--gallery-aspect-ratio)));

			@if $offset != 0 {
				top: calc(($top + $offset) * calc(var(--gallery-aspect-ratio)));
			}
		}

		&:nth-child(1) {
			top: 0;
			left: calc(var(--grid-columns-2));
		}

		&:nth-child(2) {
			left: 0;

			@include calculate-top(var(--small-image-size));
		}

		&:nth-child(3) {
			left: var(--large-image-size);

			@include calculate-top(calc(5.57% - var(--size-1)));
		}

		&:nth-child(4) {
			left: calc(var(--large-image-size));

			@include calculate-top(var(--large-image-size), calc(5.57% - var(--size-1)));
		}

		&:nth-child(1),
		&:nth-child(4) {
			width: var(--small-image-size);
		}

		&:nth-child(2),
		&:nth-child(3) {
			width: var(--large-image-size);
		}
	}

	span {
		border-radius: var(--border-radius-large) !important;

		> * {
			position: absolute !important;
			top: 0;
			left: 0;
		}
	}
}

.button {
	@include multiplier {
		width: auto;
	}
}

.usps {
	display: grid;
	gap: var(--responsive-spacing-24);
	font-weight: var(--font-weight-semi-bold);
	line-height: 1.55;

	@media (min-width: $breakpoint-sm) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: $breakpoint-md) {
		grid-template-columns: repeat(4, 1fr);
		font-size: var(--font-size-16);

		svg {
			width: var(--size-7) !important;
			height: var(--size-7) !important;
		}
	}

	> * {
		display: flex;
		gap: var(--size-2);
	}
}

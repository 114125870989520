@mixin themed-spacing($margin: 96, $padding: 72, $horizontalPadding: false) {
	margin-top: var(--responsive-spacing-#{$margin});
	margin-bottom: var(--responsive-spacing-#{$margin});

	&.has_color_theme {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: var(--responsive-spacing-#{$padding});
		padding-bottom: var(--responsive-spacing-#{$padding});

		@if $horizontalPadding {
			padding-right: var(--responsive-spacing-#{$padding});
			padding-left: var(--responsive-spacing-#{$padding});
		}
	}
}
